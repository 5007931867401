<template>
  <v-footer
    fixed
    bottom
    padless
    app
    color="#07090B">
    <v-row
      justify="center"
      class="pa-4">
      <span style="color:#F5F7FA">
        This product isn't affiliated with or endorsed by Grinding Gear Games in any way. Visual design inspired by
        <a @mouseenter="test" class="nav-hover" href="https://poe.ninja/" target="_blank" ref="noreferrer">poe.ninja</a>.
      </span>
    </v-row>
  </v-footer>
</template>

<script>
export default {

}
</script>

<style>

</style>