<template>
  <v-card class="page-card">
    <slot></slot>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>
.page-card {
  background-color: #131A20;
}
</style>